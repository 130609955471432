import styled from "styled-components";
import data from "./data.json";
import config from '../../config';
import React from "react";

export default function Lecture() {
    return (
        <>
            {data.map((item) => (
                <>
                    <TextBold>{item.title}</TextBold>
                    <p dangerouslySetInnerHTML={{ __html: item.description || "" }}></p>
                    <br />
                    {item.images &&
                        <div className="gallery">
                            <img src={item.images[0]} alt="" className="large-img image" />

                            <div className="column">
                                <img src={item.images[1]} alt="" className="small-img image" />
                                <img src={item.images[2]} alt="" className="small-img image" />
                            </div>

                            <div className="column">
                                <img src={item.images[3]} alt="" className="medium-img image" />
                                <button onClick={() => {
                                    window.open(item.videoUrl, "_blank");
                                }}>
                                    Watch Full Video
                                    <span> <img src="/images/play-circle.svg" alt="play" /> </span>
                                </button>
                            </div>
                        </div>
                    }
                    <br />
                    <ComponentTwo>
                        <div className="lecture">
                            <Label>Lecture:</Label>
                            <span dangerouslySetInnerHTML={{ __html: item.lecture || "" }}></span>
                        </div>
                        <div className="keynotes">
                            <Label>Keynote Address:</Label>
                            <span dangerouslySetInnerHTML={{ __html: item.keynotes || "" }}></span>
                        </div>
                    </ComponentTwo>
                    <br />
                    { item.otherspeakers && <> <div className="other">
                        <Label>Other speakers:</Label>
                        <span dangerouslySetInnerHTML={{ __html: item.otherspeakers || "" }}></span>
                    </div>
                    <br /> </> }
                    <div className="about-lecture">
                        <LabelHeading>About the lecture</LabelHeading>
                        { item.aboutthelecture.map((data, index) => (
                            <React.Fragment key={index}>
                                <span dangerouslySetInnerHTML={{ __html: data || "" }}></span>
                                <br /> <br />
                            </React.Fragment>
                        )) }
                        
                    </div>
                    <br /> <br />
                    <ButtonContainer>
                        <Button target="_blank" href={config.serverUrl.base + item.brochureUrl.link}>{item.brochureUrl.title}</Button>
                        <Button target="_blank" href={config.serverUrl.base + item.pressReleaseUrl.link}>{ item.pressReleaseUrl.title }</Button>
                    </ButtonContainer>
                    <hr style={{ marginTop: "5%", marginBottom: "5%" }} />
                </>
            ))}
        </>
    );
}

const ComponentOne = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const ComponentTwo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;
const TextBold = styled.p`
    font-size: 36px!important;
    color: #000000!important;
    font-family: Drlcircular!important;
    font-weight: 450!important;
    @media (max-width: 900px) {
        font-size: 16px!important;
    }
`;
const Label = styled.span`
  color: #FF5C00;
  font-size: 18px;
  font-family: Drlcircular!important;
  font-weight: 700!important;
  padding-right: 5px;
`;
const LabelHeading = styled.div`
  color: #FF5C00;
  font-size: 36px;
  font-family: Drlcircular!important;
  font-weight: 450!important;
  padding-bottom: 10px;
`;
const ButtonContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-direction:row;
    align-self: center;
    margin-bottom:50px;
    @media (max-width: 767px) {
        flex-direction:column;
        margin-bottom:40px;
    }
    @media (max-width: 820px) {
        flex-direction:column;
        margin-bottom:40px;
    }
`;
const Button = styled.a`
    text-decoration:none !important;
    text-align:center;
    cursor: pointer;
    color: #6900EF !important;
    border: 1px solid #6900EF;
    background-color: #fff;
    font-size: 18px;
    font-weight: 400;
    font-family: Drlcircular;
    padding: 8px 24px;
    border-radius: 30px;
    @media (max-width: 767px) {
        font-size: 16px;
    }
`;