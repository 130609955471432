import Lottie from "react-lottie";
import { Carousel } from "react-responsive-carousel";
import MenuSection from "../../Common/MenuSection";

import * as arrowAnime from '../../assets/Lottie/White_Arrow.json';
import { useEffect, useState } from "react";
import styled from "styled-components";
import { HR } from "../common/horizontalLine";
import Lecture from "./lecture";
import "./style.css";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: arrowAnime,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


export default function MemorialPage() {

    const [loading, setLoading] = useState(true);

    const scripts = [
        { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60952b8da0e265187c1fde0e").then(body => body.text()), isAsync: false, lastOne: false },
        { loading: fetch("webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
        { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
    ]

    const loadScript = () => {
        const htmlEl = document.querySelector('html')
        htmlEl.dataset['wfPage'] = '60952b8da0e26514531fde11'
        htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

        scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
            const loading = active.loading.then((script) => {
                console.log("CDM  =-=---=   ")
                new Function(`
              with (this) {
                eval(arguments[0])
              }
            `).call(window, script)

                return next
            })

            if (active.lastOne) {
                setLoading(false);
                /* this.setState({ renderPageBool: true }, () => {
                    window.scrollTo({ top: window.scrollY - 1, left: 0, });
                    window.scrollTo({ top: window.scrollY + 1, left: 0, });
                })*/
            }

            return active.isAsync ? next : loading
        }))

    }

    useEffect(() => {
        loadScript();
    }, []);

    return (
        <span>
            <style dangerouslySetInnerHTML={{
                __html: `
          @import url(/webflow_css/normalize.css);
          @import url(/webflow_css/components.css);
          @import url(/webflow_css/dr-reddy.css);
        ` }} />
            {loading && <span style={{ width: "100vw", height: "100vh", backgroundColor: '#fff', position: 'absolute', zIndex: 99999 }} ></span>}
            <span className="af-view">
                <div>
                    <MenuSection className="af-class-transparent" />
                    {
                        !window.matchMedia('(max-width: 767px)').matches
                            ?
                            <div id="main" className="af-class-section" style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0, paddingTop: '150px', width: '100%' }}  >
                                <Carousel
                                    style={{ height: "100%" }}
                                    showIndicators={false}
                                    showArrows={false}
                                    showStatus={false}
                                    showThumbs={false}
                                    autoPlay={false}
                                    infiniteLoop={true}
                                    dynamicHeight={false}
                                    renderArrowPrev={(clickHandler, hasPrev, label) => (
                                        hasPrev && <div className="btn prev"><button onClick={clickHandler}>
                                            <img src="/images/left-arrow.svg" />
                                        </button></div> )
                                    }
                                    renderArrowNext={(clickHandler, hasNext, label) => (
                                        hasNext && <div className="btn next"><button onClick={clickHandler}>
                                            <img src="/images/right-arrow.svg" />
                                        </button></div> )
                                    }
                                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                                        const defaultStyle = { display: "inline-flex", marginRight: "5px", borderRadius: "4px", width: "36px", height: "8px", background: "#D9D9D9" };
                                        const style = isSelected
                                            ? { ...defaultStyle, background: "#FF5C00", width: "85px" }
                                            : { ...defaultStyle };
                                        return (
                                            <span
                                                className="indicator"
                                                style={style}
                                                onClick={onClickHandler}
                                                onKeyDown={onClickHandler}
                                                value={index}
                                                key={index}
                                                role="button"
                                                tabIndex={0}
                                                aria-label={`${label} ${index + 1}`}
                                            >

                                            </span>
                                        );
                                    }}
                                >
                                    <div>
                                        <img src="/images/memorial/banner.jpg" loading="lazy" />
                                        <div className='carousal-image-overlay' />
                                    </div>
                                </Carousel>
                            </div>
                            :
                            <div id="main" className="af-class-section" style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0, width: '100%', maxHeight: '100vh' }}  >
                                <Carousel showIndicators={true} preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={10} showArrows={false} showStatus={false} showThumbs={false} autoPlay={true} infiniteLoop={true} dynamicHeight={false}>
                                    <div>
                                        <img src="/images/memorial/banner.jpg" loading="lazy" />
                                        <div className='carousal-image-overlay' />
                                    </div>
                                </Carousel>
                            </div>
                    }
                </div>

                <MainContainer>
                    <div className="af-class-section" style={{ paddingTop: "10px" }}>
                        <div className="af-class-container">
                            <ContentContainer>
                                <BodySection>
                                    <Heading>About the Dr. Anji Reddy Memorial Lecture</Heading>
                                    <HR />
                                    <TextSection>
                                    Dr. Anji Reddy Memorial Lecture is an annual lecture series themed on ‘Science, Society, Sustainability’. This series was instituted in 2023 to honour our founder Dr. K. Anji Reddy’s passion for deep science. The event brings together eminent scientists representing industry and academia. The series has been attended by luminaries from the world of science and has featured Nobel Laureates.
                                        <br /> <br /> <br />
                                        <Lecture />
                                    </TextSection>
                                </BodySection>
                            </ContentContainer>
                        </div>
                    </div>
                </MainContainer>
            </span>
        </span>
    );
}

const MainContainer = styled.div`
    position: relative;
    z-index: 1;
    width: 100vw;
    background-color: #fff;
`;

const ContentContainer = styled.div`
    position: relative;
    z-index: 1;
    width: 100%;
`;

const BodySection = styled.div`
    width: 100%;
`;

const Heading = styled.h2`
    font-size: 48px !important;
    color: #000000 !important;
    font-family: Drlcircular !important;
    font-weight: 300 !important;
    margin-top: 60px !important;
    margin-bottom: 30px !important;
    @media (max-width: 900px) {
        margin-top: 30px !important;
        margin-bottom: 15px !important;
        font-size: 24px !important;
    }
`;

const TextSection = styled.p`
    width: 90%!important;
    font-size: 18px!important;
    color: #000000!important;
    font-family: Drlcircular!important;
    font-weight: 300!important;
    margin-top: 40px !important;
    line-height: 1.8!important;
    @media (max-width: 900px) {
        margin-top: 24px !important;
        width: 100%!important;
        font-size: 16px!important;
        line-height: 24px!important;
    }
`;

const TextBold = styled.p`
    font-size: 18px!important;
    color: #000000!important;
    font-family: Drlcircular!important;
    font-weight: 700!important;
    @media (max-width: 900px) {
        font-size: 16px!important;
    }
`;